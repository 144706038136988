import { Box, Text, VStack } from "@chakra-ui/react";
import TestimoniesCarousel from "./components/TestimoniesCarousel/TestimoniesCarousel";

const ClientTestimoniesSection = () => {
    return (
        <VStack w="full" alignItems="flex-start" spacing={20}>
            <Text variant="landingTitle">TESTIMONIOS DE CLIENTES</Text>
            <Box w="full" ms={{ base: -5, md: "-9vw", "2xl": "-15vw" }}>
                <TestimoniesCarousel />
            </Box>
        </VStack>
    );
};

export default ClientTestimoniesSection;
