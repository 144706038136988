import HeroBackground from "@assets/images/home_hero_image.webp";
import { Image } from "@chakra-ui/react";

const HeroImage = () => {
    return (
        <Image
            src={HeroBackground}
            h={{ base: "auto", md: "auto" }}
            w={{ base: "full", md: "auto" }}
            maxH={{ base: "auto", md: "35rem", xl: "30rem" }}
            maxW={["80%", "100%"]}
            overflow="hidden"
        />
    );
};

export default HeroImage;
