import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./Navbar/Navbar";

const PublicLayout = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, [location.pathname]);

    return (
        <>
            <Navbar />
            <Outlet />
        </>
    );
};

export default PublicLayout;
