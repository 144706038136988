import { Button, Center, HStack, Icon, Link, Show } from "@chakra-ui/react";
import { smoothScroll } from "@utils/screen";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { Link as RouterLink } from "react-router-dom";

const navbarItems = [
    {
        label: "Guía",
        href: import.meta.env.VITE_DOCS_URL,
        mobileIcon: IoIosHelpCircleOutline,
    },
    {
        label: "Precios",
        onClick: () => smoothScroll("prices"),
    },
];

const NavbarItems = () => {
    return (
        <HStack spacing={{ base: 3, md: 8 }} alignItems={"center"}>
            {navbarItems.map((item) => (
                <Link
                    as={RouterLink}
                    to={item.href}
                    color="white"
                    key={item.label}
                    _hover={{
                        textDecoration: "none",
                        textShadow: "0 0 10px #fff",
                    }}
                    onClick={item.onClick}
                >
                    <Show above="md">{item.label}</Show>
                    <Show below="md">
                        {item.mobileIcon ? (
                            <Center h="full">
                                <Icon
                                    color="primary"
                                    as={item.mobileIcon}
                                    fontSize={"3xl"}
                                />
                            </Center>
                        ) : (
                            <Button variant={"secondary"} size={"xs"}>
                                {item.label}
                            </Button>
                        )}
                    </Show>
                </Link>
            ))}
        </HStack>
    );
};

export default NavbarItems;
