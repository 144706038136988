import { Center, chakra, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import ContactButton from "@components/ContactButton/ContactButton";

const DemoSection = () => {
    return (
        <Center w="full" my={["3rem", "9rem"]} id="demo">
            <Flex w="full" flexDir={["column", "row"]} gap={8}>
                <VStack
                    w="full"
                    spacing={{ base: 4, xl: 8 }}
                    alignItems={["center", "flex-start"]}
                    textAlign={["center", "left"]}
                >
                    <Heading
                        variant="landingHeading"
                        fontSize={{
                            base: "3rem",
                            md: "7xl",
                            "2xl": "8xl",
                        }}
                    >
                        ¿QUERÉS UN TOUR <br />
                        DE {import.meta.env.VITE_COMPANY_NAME.toUpperCase()}?
                    </Heading>
                    <Text
                        color="gray.400"
                        fontSize={{
                            base: "sm",
                            xl: "md",
                        }}
                    >
                        Contactá con nosotros y te mostraremos el funcionamiento
                        de la página.
                    </Text>
                    <ContactButton />
                </VStack>
                <chakra.video width={["full", "400px"]} controls rounded="xl">
                    <source
                        src="https://cdn.tikzet.com/static/landing_demo.mp4"
                        type="video/mp4"
                    />
                </chakra.video>
            </Flex>
        </Center>
    );
};

export default DemoSection;
