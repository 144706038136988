import { useDisclosure } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { useNextRoute } from "../hooks/useNextRoute";
import AuthModalContent from "./AuthModal/AuthModalContent";

const nextRouteKey = "next";

const AuthRouteWrapper = () => {
    const location = useLocation();
    const { getIsAuthenticated } = useContext(AuthContext);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (!getIsAuthenticated()) {
            onOpen();

            localStorage.setItem(
                nextRouteKey,
                location.pathname + location.search
            );
        } else onClose();
    }, [getIsAuthenticated, onClose, onOpen]);

    useEffect(() => {
        const next = useNextRoute();
        if (`${location.pathname}${location.search}` === next) {
            localStorage.removeItem(nextRouteKey);
        }
    }, [location]);

    return (
        <>
            {getIsAuthenticated() ? <Outlet /> : null}
            <AuthModalContent
                isOpen={isOpen}
                onClose={onClose}
                closable={false}
            />
        </>
    );
};

export default AuthRouteWrapper;
