import { FC, Suspense } from "react";
import LoadingOverlay from "../LoadingOverlay";

interface LazyLoadContainerProps {
    component: React.LazyExoticComponent<FC<any>>;
}

const LazyLoadContainer: FC<LazyLoadContainerProps> = ({
    component: Component,
}) => {
    return (
        <Suspense fallback={<LoadingOverlay />}>
            <Component />
        </Suspense>
    );
};
export const withLazyLoad = (component: React.LazyExoticComponent<FC<any>>) => {
    return <LazyLoadContainer component={component} />;
};

export default LazyLoadContainer;
