import { Image, Link } from "@chakra-ui/react";
import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import CompanyLogo from "../../assets/images/company_logo.png";
import { AuthContext } from "../../contexts/AuthContext";
import { getDefaultPath } from "../../hooks/useLocalSignIn";

const Logo = () => {
    const { getIsAuthenticated, user } = useContext(AuthContext);

    return (
        <Link
            as={RouterLink}
            to={
                getIsAuthenticated()
                    ? getDefaultPath(user?.activeOrganization?.id)
                    : "/"
            }
            py={3}
            h="full"
        >
            <Image
                w="auto"
                h="full"
                src={CompanyLogo}
                alt={import.meta.env.VITE_COMPANY_NAME}
            />
        </Link>
    );
};
export default Logo;
