import { useNextRoute } from "@hooks/useNextRoute";
import { useMemo } from "react";
import { FcGoogle } from "react-icons/fc";
import SocialMediaButton from "../SocialMediaButton/SocialMediaButton";

const googleData = {
    clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
    responseType: "token id_token",
    scope:
        "openid " +
        "https://www.googleapis.com/auth/userinfo.email " +
        "https://www.googleapis.com/auth/userinfo.profile ",
    redirectUri: `${window.location.origin}/auth/redirect/google`,
    accessType: "online",
};

interface GoogleSignInProps {
    isDisabled: boolean;
}

const GoogleSignIn = ({ isDisabled }: GoogleSignInProps) => {
    const next = useNextRoute();
    const googleRedirectUrl = useMemo(
        () =>
            "https://accounts.google.com/o/oauth2/auth" +
            `?client_id=${googleData.clientId}` +
            `&response_type=${googleData.responseType}` +
            `&scope=${googleData.scope}` +
            `&redirect_uri=${googleData.redirectUri}` +
            `&state=${next}` +
            `&nonce=${crypto.randomUUID()}` +
            `&access_type=${googleData.accessType}`,
        [next]
    );

    return (
        <SocialMediaButton
            icon={FcGoogle}
            label="Continuar con Google"
            to={isDisabled ? "#" : googleRedirectUrl}
            isDisabled={isDisabled}
        />
    );
};

export default GoogleSignIn;
