import { useEffect } from "react";

const useFacebookPixel = () => {
    useEffect(() => {
        if (!import.meta.env.PROD) return;

        const script = document.createElement("script");
        script.innerHTML = `
        !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod
                    ? n.callMethod.apply(n, arguments)
                    : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
        );
        fbq("init", "336212849293377");
        fbq("track", "PageView");
        `;
        document.head.appendChild(script);

        const noscript = document.createElement("noscript");
        const image = document.createElement("img");
        image.height = 1;
        image.width = 1;
        image.style.display = "none";
        image.src =
            "https://www.facebook.com/tr?id=336212849293377&ev=PageView&noscript=1";
        noscript.appendChild(image);
        document.head.appendChild(noscript);
    }, []);
};

export default useFacebookPixel;
