import { Country } from "@api/types";
import { Box, FormLabel, Show, VStack } from "@chakra-ui/react";
import ThemedSelect from "@components/ThemeSelect/ThemedSelect";
import { getEmojiByCountry, getNameByCountry } from "@utils/countries";
import { Dispatch, SetStateAction, useMemo } from "react";
import CountryItem from "./CountryItem/CountryItem";

const getMobileSelectOptions = (countries: Country[]) =>
    countries.map((x) => ({
        label: `${getEmojiByCountry(x)} ${getNameByCountry(x)}`,
        value: x,
    }));

interface Props {
    countries: Country[];
    selectedCountry: Country;
    setSelectedCountry: Dispatch<SetStateAction<Country>>;
}

const CountrySelector = ({
    countries,
    selectedCountry,
    setSelectedCountry,
}: Props) => {
    const mobileOptions = useMemo(
        () => getMobileSelectOptions(countries),
        [countries]
    );

    return (
        <VStack
            alignItems="flex-start"
            bg="light_gray"
            roundedTopLeft={["2xl", "2xl"]}
            roundedTopRight={["2xl", 0]}
            roundedBottomLeft={[0, "2xl"]}
            ps={[6, 7]}
            pe={[6, 0]}
            py={5}
        >
            <Show above="md">
                {countries.map((c) => (
                    <CountryItem
                        key={c.toString()}
                        country={c}
                        isSelected={c === selectedCountry}
                        onClick={setSelectedCountry}
                    />
                ))}
            </Show>
            <Show below="md">
                <VStack alignItems="flex-start" w="full">
                    <FormLabel>Elegir país</FormLabel>
                    <Box w="full" bg="#2C2C31" rounded="full" px={3}>
                        <ThemedSelect
                            options={mobileOptions}
                            value={mobileOptions.find(
                                (x) => x.value === selectedCountry
                            )}
                            onChange={(x) => setSelectedCountry(x?.value ?? 0)}
                            isSearchable={false}
                        />
                    </Box>
                </VStack>
            </Show>
        </VStack>
    );
};

export default CountrySelector;
