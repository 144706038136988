import { Heading, HStack, Image, Show, Text, VStack } from "@chakra-ui/react";

interface Props {
    stepImage: string;
    title: string;
    description: string;
}

const StepItem = ({ stepImage, title, description }: Props) => {
    return (
        <VStack
            alignItems="flex-start"
            spacing={5}
            w="full"
            h="full"
            justifyContent="center"
        >
            <HStack spacing={4}>
                <Image src={stepImage} w="50px" />
                <Heading
                    fontFamily="landingHeading"
                    fontSize={["2xl", "1.8rem"]}
                >
                    {title}
                </Heading>
            </HStack>
            <Show below="md">
                <Text color="gray.300" fontSize="sm">
                    {description}
                </Text>
            </Show>
        </VStack>
    );
};

export default StepItem;
