import { Flex } from "@chakra-ui/react";
import HeroBackground from "./components/HeroImage/HeroImage";
import TitleSection from "./components/TitleSection/TitleSection";

const Hero = () => {
    return (
        <Flex
            justifyContent="center"
            alignItems={["flex-start", "center"]}
            w="full"
            minH="100vh"
            pt={{ base: 16, md: 0 }}
        >
            <Flex
                w={"full"}
                minH="fit-content"
                flexDir={{ base: "column-reverse", md: "row" }}
                justifyContent={{ base: "flex-start", md: "space-between" }}
                alignItems="center"
                gap={{ base: 16, md: "5rem" }}
            >
                <TitleSection />
                <HeroBackground />
            </Flex>
        </Flex>
    );
};

export default Hero;
