import WrinkledPaperBackground from "@assets/images/wrinkled_paper.webp";
import { Heading, HStack, Icon, Link, Text, VStack } from "@chakra-ui/react";
import { IconType } from "react-icons/lib";

interface Props {
    title: string;
    icon: IconType;
    description: string;
    cta: {
        href: string;
        label: string;
    };
}

const FaqCard = ({ title, icon, description, cta }: Props) => {
    return (
        <VStack
            w="full"
            alignItems="flex-start"
            bg={`linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${WrinkledPaperBackground})`}
            bgPos="center center"
            bgSize="cover"
            px={[8, 12]}
            py={[16, 8]}
            rounded="md"
            spacing={5}
        >
            <HStack spacing={2} alignItems="center">
                <Icon as={icon} boxSize={6} color="white" />
                <Heading fontSize={["lg", "xl"]} fontWeight="500">
                    {title}
                </Heading>
            </HStack>
            <Text color="gray.400" fontSize={["sm", "md"]}>
                {description}
            </Text>
            <Link
                mt={[1, 3]}
                fontSize={["sm", "md"]}
                color="primary"
                href={cta.href}
                isExternal
            >
                {cta.label}
            </Link>
        </VStack>
    );
};

export default FaqCard;
