import { Button, HStack, useDisclosure } from "@chakra-ui/react";
import AuthModalContent from "../AuthModal/AuthModalContent";

const NavbarAuthPrompt = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <HStack spacing={5}>
            <Button
                onClick={onOpen}
                size={{ base: "xs", md: "md" }}
                variant={{ base: "striking", md: "primary" }}
            >
                Ingresá
            </Button>
            <AuthModalContent isOpen={isOpen} onClose={onClose} />
        </HStack>
    );
};

export default NavbarAuthPrompt;
