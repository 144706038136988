import { apiClient } from "@api/config";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

interface UseGetEventsQueryResponse {
    events: Event[];
}

export interface Event {
    id: string;
    name: string;
    startDateTime: string;
    banner: {
        url: string;
        isVideo: boolean;
    };
    slug: string;
}

export const useGetEventsQuery = (
    options?: Omit<
        UseQueryOptions<UseGetEventsQueryResponse["events"]>,
        "queryKey"
    >
) =>
    useQuery<UseGetEventsQueryResponse["events"]>(
        [useGetEventsQuery.name],
        async () => {
            const { data } = await apiClient.get("/events");
            return data.events;
        },
        options
    );
