import {
    Center,
    GridItem,
    Show,
    SimpleGrid,
    Text,
    VStack,
} from "@chakra-ui/react";
import EventCreation from "./assets/event_creation.webp";
import OrgCreation from "./assets/org_creation.webp";
import Step1 from "./assets/step1.svg";
import Step2 from "./assets/step2.svg";
import Step3 from "./assets/step3.svg";
import TicketsManagement from "./assets/tickets_management.webp";
import CardContent from "./components/CardContent/CardContent";
import CreateOrgButton from "./components/CreateOrgButton/CreateOrgButton";
import StepItem from "./components/StepItem/StepItem";

const HowToSection = () => {
    return (
        <VStack w="full" alignItems="flex-start" spacing={[12, 20]}>
            <Text variant="landingTitle">¿COMO EMPIEZO A USAR EL SISTEMA?</Text>
            <VStack spacing={10} w="full">
                <SimpleGrid columns={[1, 3]} spacing={[6, 10]} w="full">
                    <GridItem colSpan={1}>
                        <StepItem
                            stepImage={Step1}
                            title="REGISTRATE GRATIS"
                            description="Podés registrarte y obtener una prueba gratuita para comenzar a enviar y vender entradas. Solo te pediremos un nombre y una foto para el perfil de tu productora."
                        />
                    </GridItem>
                    <GridItem colSpan={1}>
                        <StepItem
                            stepImage={Step2}
                            title="REGISTRÁ TU EVENTO"
                            description="Esto incluye detalles como la ubicación, fecha y precio de las entradas. Durante este proceso, podrás vincular una cuenta para realizar los cobros."
                        />
                    </GridItem>
                    <GridItem colSpan={1}>
                        <StepItem
                            stepImage={Step3}
                            title="GESTIONA INGRESOS"
                            description="El día del evento, podés validar los QRs con tu celular desde Tikzet. Todos los QRs son únicos y pueden ser validados una vez."
                        />
                    </GridItem>
                </SimpleGrid>
                <Show above="md">
                    <SimpleGrid columns={[1, 3]} spacing={10} w="full">
                        <GridItem colSpan={1}>
                            <CardContent
                                description="Podés registrarte y obtener una prueba gratuita para comenzar a enviar y vender entradas. Solo te pediremos un nombre y una foto para el perfil de tu productora."
                                image={OrgCreation}
                            />
                        </GridItem>
                        <GridItem colSpan={1}>
                            <CardContent
                                description="Esto incluye detalles como la ubicación, fecha y precio de las entradas. Durante este proceso, podrás vincular una cuenta para realizar los cobros."
                                image={EventCreation}
                            />
                        </GridItem>
                        <GridItem colSpan={1}>
                            <CardContent
                                description="El día del evento, podés validar los QRs con tu celular desde Tikzet. Todos los QRs son únicos y pueden ser validados una vez."
                                image={TicketsManagement}
                            />
                        </GridItem>
                    </SimpleGrid>
                </Show>
            </VStack>
            <Center w={["fit-content", "full"]}>
                <CreateOrgButton />
            </Center>
        </VStack>
    );
};

export default HowToSection;
