import { HStack, Icon, Link, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { Link as RouterLink, matchRoutes } from "react-router-dom";

interface Props {
    to: string;
    label: string;
    icon: IconType | React.ComponentType;
    closeMobileSidebar?: () => void;
}

const SideBarItem = ({ to, label, icon, closeMobileSidebar }: Props) => {
    const match = matchRoutes(
        [
            {
                path: to,
            },
        ],
        location
    );

    const isActive = match && match.length > 0;

    return (
        <Link
            as={RouterLink}
            to={to}
            key={to}
            w={"fit-content"}
            onClick={closeMobileSidebar}
            whiteSpace={"nowrap"}
            ps={10}
            pe={20}
        >
            <HStack spacing={5} alignItems={"flex-start"}>
                <Icon
                    as={icon}
                    fontSize={"xl"}
                    color={isActive ? "primary" : "white"}
                    userSelect="none"
                />
                <Text
                    fontWeight={isActive ? "bold" : "normal"}
                    color={isActive ? "primary" : "white"}
                    fontSize={"sm"}
                >
                    {label}
                </Text>
            </HStack>
        </Link>
    );
};

export default SideBarItem;
