import CoccoteensLogo from "@assets/images/clientLogos/coccoteens_logo.webp";
import ElsaLogo from "@assets/images/clientLogos/elsa_logo.webp";
import LabelLogo from "@assets/images/clientLogos/label_logo.webp";
import LuxLogo from "@assets/images/clientLogos/lux_logo.webp";
import MalibuLogo from "@assets/images/clientLogos/malibu_logo.webp";
import NuevaescuelaLogo from "@assets/images/clientLogos/nuevaescuela_logo.webp";
import SunriseLogo from "@assets/images/clientLogos/sunrise_logo.webp";
import TequilaLogo from "@assets/images/clientLogos/tequila_logo.webp";
import { Box, Center, VStack } from "@chakra-ui/react";
import CarouselDots from "@components/CarouselDots/CarouselDots";
import { useEmbla } from "@hooks/useEmbla";
import TestimoniesCarouselItem from "./components/TestimoniesCarouselItem/TestimoniesCarouselItem";

const testimonies = [
    {
        reviewerImage: TequilaLogo,
        review: "El sistema es impecable, fácil de usar para nuestros clientes, quienes pueden adquirir sus entradas en pocos pasos. Para nosotros en Tequila Club Córdoba, es una herramienta con mucho potencial futuro. Les damos una recomendación del 100%!",
        company: "Tequila Club",
        instagram: "tequilaclub.cba",
    },
    {
        reviewerImage: ElsaLogo,
        review: "Estaba buscando una plataforma de venta de tickets para mis fiestas que también generara un código QR para mis invitados, y la verdad es que Tikzet superó todas mis expectativas. Recomiendo esta página totalmente.",
        company: "La Fiestita de Elsa",
        instagram: "lafiestitadeelsa",
    },
    {
        reviewerImage: CoccoteensLogo,
        review: "Tikzet te salva. Con un montón de cosas en la cabeza cuando organizamos un evento, sabemos que con Tikzet en 5 minutos ya estamos vendiendo entradas. Super intuitiva y fácil de usar, tanto para nosotros como para nuestros clientes.",
        company: "Coccoteens",
        instagram: "coccoteens",
    },
    {
        reviewerImage: LabelLogo,
        review: "La aplicación es genial para organizar tu evento, tiene funciones fáciles de usar y es sencilla de entender. Hay que resaltar la buena atención por parte de soporte, siempre respondiendo al instante.",
        company: "Label Fest",
        instagram: "label.fest",
    },
    {
        reviewerImage: LuxLogo,
        review: "Me parece una aplicación super completa. Las opciones que tiene para crear distintos tipos de entradas y consumos es excelente.  Armar un evento es re fácil. La entrega de los QRs es inmediata y nuestro clientes quedaron muy conformes.",
        company: "Lux Disco Night",
        instagram: "luxdisconight",
    },
    {
        reviewerImage: MalibuLogo,
        review: "Muy buena la página, super intuitiva para nuestros clientes a la hora de comprar. A nosotros nos funcionó siempre sin problemas. Aunque es bastante nueva, funciona muy bien y se ve que tiene mucho potencial. ",
        company: "Malibú Session",
        instagram: "malibusession",
    },
    {
        reviewerImage: NuevaescuelaLogo,
        review: "Conocí Tikzet por internet y me pareció excelente. Súper recomendable. Destaco que cada vez que tuve una duda o consulta, me contestaron al instante para que pueda resolverlo. En cuanto al funcionamiento durante el evento funcionó a la perfección.",
        company: "Nueva Escuela",
        instagram: "nuevaescuelaproducciones",
    },
    {
        reviewerImage: SunriseLogo,
        review: "A mí lo que más me gustó de Tikzet es lo fácil que se integra con Mercado Pago y los envíos de QR por WhatsApp.  Además, es súper intuitiva; crear y agregar distintos tipos de tickets, así como organizar un evento en la plataforma, es super fácil.",
        company: "Sunrise Group",
        instagram: "sunrisegroup__",
    },
];

const TestimoniesCarousel = () => {
    const { emblaRef, currentIndex, setCurrentIndex } = useEmbla({
        loop: true,
        align: "center",
    });

    return (
        <VStack alignItems="center" spacing={14} w="100vw">
            <VStack w="full" overflow="hidden" ref={emblaRef}>
                <Box w="full" display="flex" alignItems="stretch">
                    {testimonies.map((t) => (
                        <Center
                            key={t.company}
                            flex={["0 0 100%", "0 0 60%"]}
                            px={5}
                        >
                            <TestimoniesCarouselItem testimony={t} />
                        </Center>
                    ))}
                </Box>
            </VStack>
            <CarouselDots
                total={testimonies.length}
                current={currentIndex}
                setCurrentIndex={setCurrentIndex}
            />
        </VStack>
    );
};

export default TestimoniesCarousel;
