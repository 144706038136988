import { Country } from "@api/types";
import { useGetPremiumCreditsPriceQuery } from "@api/useCases/premiumCredits/queries/getPremiumCreditsPrice/useGetPremiumCreditsPriceQuery";
import { Button, Flex, Text, VStack } from "@chakra-ui/react";
import ContactButton from "@components/ContactButton/ContactButton";
import { getCurrencyCodeByCountry } from "@utils/countries";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import CountrySelector from "./components/CountrySelector/CountrySelector";
import PriceDisplay from "./components/PriceDisplay/PriceDisplay";

const PriceSection = () => {
    const { data: prices, isSuccess } = useGetPremiumCreditsPriceQuery();
    const [country, setCountry] = useState<Country>(Country.Argentina);

    const currentPrice = useMemo(
        () => prices?.prices.find((x) => x.country === country)?.unitPrice,
        [prices, country]
    );

    if (!isSuccess || currentPrice === undefined) return null;

    return (
        <VStack w="full" alignItems="flex-start" spacing={12} id="prices">
            <Text variant="landingTitle">COBERTURA Y PRECIOS</Text>
            <Text color="gray.400">
                En Tikzet comprás créditos por adelantado y usás uno por cada
                ticket QR que vendas o envíes. Los créditos no vencen y se
                compran fácilmente desde la sección &quot;Créditos&quot; en la
                web.
            </Text>
            <Flex w="full" flexDir={["column", "row"]} justifyContent="center">
                <CountrySelector
                    countries={prices.prices.map((x) => x.country)}
                    selectedCountry={country}
                    setSelectedCountry={setCountry}
                />
                <PriceDisplay
                    bundles={prices.bundles}
                    unitPrice={currentPrice}
                    currency={getCurrencyCodeByCountry(country)}
                />
            </Flex>
            <Flex
                w={["fit-content", "full"]}
                flexDir={["column", "row"]}
                justifyContent="flex-start"
                gap={6}
                mx="auto"
            >
                <Button
                    variant="striking"
                    as={Link}
                    to={"/organizations/new?from=landing"}
                >
                    Prueba gratis
                </Button>
                <ContactButton />
            </Flex>
        </VStack>
    );
};

export default PriceSection;
