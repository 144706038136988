import { apiClient } from "@api/config";
import { Country } from "@api/types";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

export interface UseGetMyProfileQueryResponse {
    id: string;
    email: string;
    activeOrganization?: UseGetMyProfileQueryResponseActiveOrganization;
    authData: UseGetMyProfileQueryResponseAuthData;
    createdAt: string;
    createdFirstEvent: boolean;
    country?: Country;
}

export interface UseGetMyProfileQueryResponseAuthData {
    isBusinessOwner: boolean;
    isPublicRelations: boolean;
}

export interface UseGetMyProfileQueryResponseActiveOrganization {
    id: string;
    permissions: {
        canManageEvents: boolean;
        canValidateQRs: boolean;
        canEditProfile: boolean;
    };
}

export const useGetMyProfileQuery = (
    authHeader: string,
    options?: Omit<
        UseQueryOptions<UseGetMyProfileQueryResponse, AxiosError>,
        "queryKey"
    >
) =>
    useQuery(
        [useGetMyProfileQuery.name],
        async () => {
            const { data } = await apiClient.get<UseGetMyProfileQueryResponse>(
                "users/me",
                {
                    headers: {
                        Authorization: `Bearer ${authHeader}`,
                    },
                }
            );
            return data;
        },
        options
    );
