import * as Sentry from "@sentry/react";

export const initializeSentry = () => {
    if (!import.meta.env.PROD) return;

    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
            Sentry.browserTracingIntegration(),
        ],
        tracePropagationTargets: [import.meta.env.VITE_API_URL],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
};
