import ArrowLeftToRight from "@assets/images/arrow_left_2_right.svg";
import { Button, HStack, Image, Show } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const CreateOrgButton = () => {
    return (
        <HStack color="white" spacing={5} justifyContent="center">
            <Show above="md">
                <Image
                    pb={"3rem"}
                    w={{ lg: "7rem", "2xl": "10rem" }}
                    src={ArrowLeftToRight}
                />
            </Show>
            <Button
                zIndex={2}
                as={Link}
                to={"/organizations/new?from=landing"}
                variant={"striking"}
            >
                Crear cuenta gratis
            </Button>
            <Show above="md">
                <Image
                    style={{ transform: "scaleX(-1)" }}
                    pb={"3rem"}
                    w={{ lg: "7rem", "2xl": "10rem" }}
                    src={ArrowLeftToRight}
                />
            </Show>
        </HStack>
    );
};

export default CreateOrgButton;
