import { Box, Heading, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import SupportBg from "../assets/images/support_bg.jpg";

const SupportLayout = () => {
    const [title, setTitle] = useState("");

    return (
        <VStack>
            <Box
                w="full"
                h="30%"
                bgImage={SupportBg}
                bgSize="cover"
                bgRepeat={"no-repeat"}
                bgPos="center"
                color="white"
                px={"5rem"}
                pb={10}
                pt={20}
            >
                <Heading fontSize={"3xl"}>{title}</Heading>
            </Box>
            <Box w="full" p={5}>
                <Outlet context={{ setTitle }} />
            </Box>
        </VStack>
    );
};

export const useSupportLayoutContext = () => {
    return useOutletContext<{ setTitle: (title: string) => void }>();
};

export default SupportLayout;
