import { useGetEventsQuery } from "@api/useCases/events/queries/getEvents/useGetEventsQuery";
import {
    HStack,
    Skeleton,
    Text,
    useBreakpointValue,
    VStack,
} from "@chakra-ui/react";
import EventsCarousel from "./components/EventsCarousel/EventsCarousel";

const RecentEvents = () => {
    const { data: events, isSuccess, isLoading } = useGetEventsQuery();
    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <VStack w="full" alignItems="flex-start" spacing={16}>
            <Text variant="landingTitle">EVENTOS RECIENTES</Text>
            <HStack
                w="100vw"
                spacing={5}
                minW="fit-content"
                justifyContent="center"
                ms={{ base: -5, md: "-9vw", "2xl": "-15vw" }}
            >
                {isSuccess && <EventsCarousel events={events} />}
                {isLoading &&
                    Array.from({ length: isMobile ? 1 : 10 }).map((_, i) => (
                        <Skeleton
                            boxSize={{ base: "65vw", md: "240px" }}
                            rounded="2xl"
                            key={i}
                        />
                    ))}
            </HStack>
        </VStack>
    );
};

export default RecentEvents;
