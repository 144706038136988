import { Heading, HStack, Image, Text, VStack } from "@chakra-ui/react";

interface Props {
    value: string;
    amount: number;
    image: string;
    currency: string;
}

const PriceItem = ({ value, amount, image, currency }: Props) => {
    return (
        <HStack w="full" spacing={8}>
            <VStack alignItems="center" w={"95px"}>
                <Image src={image} />
                <Text
                    whiteSpace="nowrap"
                    color="primary"
                    fontWeight="bold"
                    fontSize="sm"
                >
                    {amount} créditos
                </Text>
            </VStack>
            <Heading fontWeight="light" fontSize={["xl", "3xl"]}>
                {currency} {value} por QR
            </Heading>
        </HStack>
    );
};

export default PriceItem;
