import { Text } from "@chakra-ui/react";
import ThemedJoyride from "@components/ThemedJoyride/ThemedJoyride";
import { useAuthContext } from "@contexts/AuthContext";
import { Step } from "react-joyride";

export const tourKeys = {
    stepOne: "navigation-intro-step-one",
    stepTwo: "navigation-intro-step-two",
};

const steps: Step[] = [
    {
        target: `#${tourKeys.stepOne}`,
        content: (
            <Text as="span">
                Crear un evento es el <strong>primer paso</strong> para
                gestionar tus ingresos. <br />
                <br />
                Es <strong>super fácil</strong> y podes hacerlo en un par de
                minutos.
            </Text>
        ),
        title: <Text fontWeight="bold">Crear evento</Text>,
        disableBeacon: true,
        showProgress: true,
        disableOverlayClose: true,
    },
    {
        target: `#${tourKeys.stepTwo}`,
        content: (
            <Text as="span">
                Acá encontras todas las funcionalidades más importantes de
                Tikzet, como el <strong>Escáner de QRs</strong> y{" "}
                <strong>compra de Créditos </strong> entre otros.
            </Text>
        ),
        title: <Text fontWeight="bold">Menú principal</Text>,
        showProgress: true,
        placement: "right",
    },
];

const NavigationIntroGuidedTour = () => {
    const { user } = useAuthContext();

    return (
        <ThemedJoyride
            steps={steps}
            continuous
            tourName="navigation-intro"
            run={!user?.createdFirstEvent}
        />
    );
};

export default NavigationIntroGuidedTour;
