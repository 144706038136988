import { apiClient } from "@api/config";
import { Country, EventStatus } from "@api/types";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

export interface UseGetEventBySlugResponse {
    id: string;
    name: string;
    description: string;
    address: string;
    startDateTime: string;
    organization: {
        profilePicture: string;
        name: string;
        serviceChargeEnabled: boolean;
    };
    flyers: {
        url: string;
        isVideo: boolean;
    }[];
    tags: {
        name: string;
    }[];
    status: EventStatus;
    minManAge: number;
    minWomenAge: number;
    cheapestProduct: {
        id: string;
        name: string;
        price: number;
    } | null;
    finished: boolean;
    hasOnlineSale: boolean;
    country: Country;
}

interface UseGetEventBySlugRequest {
    slug: string;
}

export const useGetEventBySlugQuery = (
    request: UseGetEventBySlugRequest,
    options?: Omit<UseQueryOptions<UseGetEventBySlugResponse>, "queryKey">
) =>
    useQuery(
        [useGetEventBySlugQuery.name, request],
        async () => {
            const { data } = await apiClient.get<UseGetEventBySlugResponse>(
                `/events/by-slug/${request.slug}`
            );
            return data;
        },
        options
    );
