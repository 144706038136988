import { Event } from "@api/useCases/events/queries/getEvents/useGetEventsQuery";
import { Box, chakra, Image, Text, VStack } from "@chakra-ui/react";
import { utcToLocaleMoment } from "@utils/moment";
import { Link as RouterLink } from "react-router-dom";

interface Props {
    event: Event;
}

const EventsCarouselItem = ({ event }: Props) => {
    return (
        <VStack
            alignItems="flex-start"
            maxW={{ base: "65vw", md: "240px" }}
            as={RouterLink}
            to={`/events/${event.slug}`}
        >
            <Box boxSize={{ base: "65vw", md: "240px" }}>
                {event.banner.isVideo ? (
                    <chakra.video
                        muted
                        rounded={"xl"}
                        loop
                        autoPlay
                        playsInline
                        objectFit="cover"
                        w="full"
                        h="full"
                    >
                        <source src={event.banner.url} />
                    </chakra.video>
                ) : (
                    <Image
                        rounded={"xl"}
                        src={event.banner.url}
                        width="100%"
                        h="100%"
                        objectFit="cover"
                    />
                )}
            </Box>
            <Text
                fontWeight="bold"
                noOfLines={1}
                fontSize={{ base: "lg", md: "md" }}
            >
                {event.name}
            </Text>
            <Text fontSize="sm" color="gray.400" textTransform="capitalize">
                {utcToLocaleMoment(event.startDateTime).format(
                    "dddd DD [de] MMMM"
                )}
            </Text>
        </VStack>
    );
};

export default EventsCarouselItem;
