import { Center, Spinner } from "@chakra-ui/react";

const LoadingOverlay = () => {
    return (
        <Center
            w="full"
            h="full"
            pos="fixed"
            top={0}
            left={0}
            bgColor="rgba(0,0,0,.8)"
            zIndex={500}
        >
            <Spinner size="xl" color="primary" />
        </Center>
    );
};

export default LoadingOverlay;
