import { Country } from "@api/types";
import { HStack, Image, Text } from "@chakra-ui/react";
import { getImageByCountry, getNameByCountry } from "@utils/countries";

interface Props {
    country: Country;
    isSelected: boolean;
    onClick: (country: Country) => void;
}

const CountryItem = ({ country, isSelected, onClick }: Props) => {
    return (
        <HStack
            cursor="pointer"
            onClick={() => onClick(country)}
            spacing={4}
            alignItems="flex-start"
            bg={isSelected ? "dark_gray" : "none"}
            py={3}
            ps={6}
            pe={20}
            roundedStart="full"
            w="full"
            borderEndColor="primary"
            borderEndWidth={isSelected ? "4px" : "0px"}
        >
            <Image w="26px" src={getImageByCountry(country)} />
            <Text
                color={isSelected ? "primary" : "white"}
                fontWeight={isSelected ? "bold" : "normal"}
            >
                {getNameByCountry(country)}
            </Text>
        </HStack>
    );
};

export default CountryItem;
