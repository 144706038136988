import WrinkledPaperBackground from "@assets/images/wrinkled_paper.webp";
import { Avatar, Heading, HStack, Link, Text, VStack } from "@chakra-ui/react";

type Props = {
    testimony: {
        reviewerImage: string;
        review: string;
        company: string;
        instagram: string;
    };
};

const TestimoniesCarouselItem = ({ testimony }: Props) => {
    return (
        <VStack
            w="fit-content"
            alignItems="flex-start"
            spacing={[8, 10]}
            bg={`linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${WrinkledPaperBackground})`}
            bgPos="center center"
            bgSize="cover"
            px={[8, 14]}
            py={[10, 12]}
            userSelect="none"
            maxW="full"
            h="full"
            rounded="md"
        >
            <HStack spacing={3}>
                <Avatar
                    src={testimony.reviewerImage}
                    name={testimony.company}
                    borderWidth={2}
                    borderColor="white"
                    boxSize={[12, 14]}
                />
                <VStack alignItems="flex-start" spacing={1}>
                    <Heading fontSize={["lg", "xl"]} textAlign="center">
                        {testimony.company}
                    </Heading>
                    <Link
                        fontSize={["xs", "sm"]}
                        color="gray.300"
                        href={`https://instagram.com/${testimony.instagram}`}
                        isExternal
                    >
                        @{testimony.instagram}
                    </Link>
                </VStack>
            </HStack>
            <Text color="gray.400" fontSize={["md", "lg"]}>
                &quot;{testimony.review.trim()}&quot;
            </Text>
        </VStack>
    );
};

export default TestimoniesCarouselItem;
