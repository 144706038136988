import Background from "@assets/images/home_bg.webp";
import { Box, VStack } from "@chakra-ui/react";
import Footer from "@components/Footer/Footer";
import { useNavbarHeight } from "@hooks/useNavbarHeight";
import useRedirectToAppIfAuthenticated from "@hooks/useRedirectToAppIfAuthenticated";
import ClientTestimoniesSection from "./components/ClientTestimoniesSection/ClientTestimoniesSection";
import DemoSection from "./components/DemoSection/DemoSection";
import FaqsSection from "./components/FaqsSection/FaqsSection";
import Hero from "./components/Hero/Hero";
import HowToSection from "./components/HowToSection/HowToSection";
import PriceSection from "./components/PriceSection/PriceSection";
import RecentEvents from "./components/RecentEvents/RecentEvents";
import Services from "./components/Services/Services";

const HomePage = () => {
    const navbarHeight = useNavbarHeight();

    useRedirectToAppIfAuthenticated();

    return (
        <VStack
            w="full"
            spacing="10rem"
            pt={{ base: navbarHeight, md: 0 }}
            px={{ base: 5, md: "9vw", "2xl": "15vw" }}
            backgroundImage={Background}
            bgPosition="center top"
            bgSize="contain"
            bgRepeat="no-repeat"
        >
            <Hero />
            <RecentEvents />
            <Services />
            <HowToSection />
            <PriceSection />
            <ClientTestimoniesSection />
            <FaqsSection />
            <DemoSection />
            <Box w="100vw">
                <Footer />
            </Box>
        </VStack>
    );
};

export default HomePage;
