import {
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Heading,
    Icon,
    Text,
} from "@chakra-ui/react";
import { BsDash } from "react-icons/bs";
import { IoIosAdd } from "react-icons/io";

interface Props {
    question: string;
    answer: string;
}

const FaqItem = ({ question, answer }: Props) => {
    return (
        <AccordionItem
            borderBottom="1px solid #1A1D23"
            borderTop={"none"}
            w="full"
        >
            {({ isExpanded }) => (
                <>
                    <AccordionButton justifyContent={"space-between"} p={0}>
                        <Heading
                            textAlign="left"
                            fontWeight="normal"
                            fontSize={["lg", "xl"]}
                            pb={2}
                        >
                            {question}
                        </Heading>
                        <Icon
                            color="primary"
                            opacity={0.5}
                            fontSize="3xl"
                            as={isExpanded ? BsDash : IoIosAdd}
                        />
                    </AccordionButton>
                    <AccordionPanel py={5} px={0}>
                        <Text dangerouslySetInnerHTML={{ __html: answer }} />
                    </AccordionPanel>
                </>
            )}
        </AccordionItem>
    );
};

export default FaqItem;
