import { Flex, Heading, VStack } from "@chakra-ui/react";
import { useNavbarHeight } from "@hooks/useNavbarHeight";
import { useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import Background from "../../assets/images/auth_bg.webp";

const AuthLayout = () => {
    const navbarHeight = useNavbarHeight();
    const [title, setTitle] = useState("");

    return (
        <Flex
            w="full"
            h="100vh"
            justifyContent={{ base: "flex-start", md: "center" }}
            alignItems={"flex-start"}
            p={5}
            pt={`calc(${navbarHeight} + 1rem)`}
            bgImage={Background}
            bgPos={"center center"}
            bgSize={"cover"}
            bgRepeat={"no-repeat"}
        >
            <VStack
                alignItems={"center"}
                w="full"
                maxW="40rem"
                minW="fit-content"
                px={[5, "7.5rem"]}
                pt={8}
                pb={20}
                bgColor="light_gray"
                rounded="xl"
                spacing={10}
            >
                <Heading color="white" fontSize={"2xl"}>
                    {title}
                </Heading>
                <Outlet context={{ setTitle }} />
            </VStack>
        </Flex>
    );
};

export const useAuthLayoutContext = () => {
    return useOutletContext<{ setTitle: (title: string) => void }>();
};

export default AuthLayout;
