import { useAuthContext } from "@contexts/AuthContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getDefaultPath } from "./useLocalSignIn";

const useRedirectToAppIfAuthenticated = () => {
    const { getIsAuthenticated, user } = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (getIsAuthenticated()) {
            navigate(getDefaultPath(user?.activeOrganization?.id));
        }
    }, [getIsAuthenticated, user]);
};

export default useRedirectToAppIfAuthenticated;
