import { Box, Flex, Icon, Link, Text, VStack } from "@chakra-ui/react";
import { BsInstagram } from "react-icons/bs";
import { Link as RouterLink } from "react-router-dom";
import Logo from "../Navbar/Logo";

const Footer = () => {
    return (
        <Flex
            flexDir={{ base: "column", md: "row" }}
            justifyContent={"flex-start"}
            alignItems={{ base: "flex-start", md: "center" }}
            w="full"
            py={5}
            px={{ base: 5, md: 10 }}
            gap={{ base: 6, md: 10 }}
            borderTop="1px solid"
            borderTopColor="gray.900"
        >
            <Box h="2rem">
                <Logo />
            </Box>
            <Link
                as={RouterLink}
                to={`https://instagram.com/${
                    import.meta.env.VITE_COMPANY_INSTAGRAM
                }`}
                target="_blank"
                display="flex"
            >
                <Icon fontSize="2xl" color="gray.500" as={BsInstagram} />
            </Link>
            <VStack spacing={2} alignItems={"flex-start"} fontSize={"sm"}>
                <Text color="gray.400">
                    Copyright© {new Date().getFullYear()}. Todos los derechos
                    reservados.
                </Text>
                <Link
                    color="primary"
                    fontSize="xs"
                    as={RouterLink}
                    target="_blank"
                    to="/tyc"
                >
                    Términos y condiciones.
                </Link>
            </VStack>
        </Flex>
    );
};

export default Footer;
