import { Box, HStack } from "@chakra-ui/react";

interface Props {
    total: number;
    current: number;
    setCurrentIndex: (index: number) => void;
}

const FlyersIndicator = ({ total, current, setCurrentIndex }: Props) => {
    return (
        <HStack spacing={2}>
            {Array.from(new Array(total)).map((_, i) => (
                <Box
                    key={i}
                    rounded={"full"}
                    bgColor={i === current ? "white" : "gray.500"}
                    minW={".8rem"}
                    minH=".8rem"
                    cursor={"pointer"}
                    onClick={() => setCurrentIndex(i)}
                ></Box>
            ))}
        </HStack>
    );
};

export default FlyersIndicator;
