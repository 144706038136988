import { useGetEventQuery } from "@api/useCases/events/queries/getEvent/useGetEventQuery";
import { useGetEventBySlugQuery } from "@api/useCases/events/queries/getEventBySlug/useGetEventBySlug";
import { Box, Show, VStack } from "@chakra-ui/react";
import FlyerBackground from "@components/FlyerBackground";
import Footer from "@components/Footer/Footer";
import LoadingOverlay from "@components/LoadingOverlay";
import { useNavbarHeight } from "@hooks/useNavbarHeight";
import { useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";

const PublicEventLayout = () => {
    const { eventSlug, eventId } = useParams();
    const navbarHeight = useNavbarHeight();

    const {
        data: eventFromSlug,
        isSuccess: eventFromSlugSuccess,
        isLoading: eventFromSlugLoading,
    } = useGetEventBySlugQuery(
        {
            slug: eventSlug!,
        },
        {
            enabled: !!eventSlug,
        }
    );

    const {
        data: eventFromId,
        isSuccess: eventFromIdSuccess,
        isLoading: eventFromIdLoading,
    } = useGetEventQuery(
        {
            id: eventId!,
        },
        {
            enabled: !!eventId,
        }
    );

    const banner = useMemo(() => {
        if (eventFromSlugSuccess) return eventFromSlug?.flyers[0];
        if (eventFromIdSuccess) return eventFromId?.banner;
        return null;
    }, [eventFromSlugSuccess, eventFromIdSuccess, eventFromSlug, eventFromId]);

    if (
        (!!eventFromSlug && eventFromSlugLoading) ||
        (!!eventFromId && eventFromIdLoading)
    )
        return <LoadingOverlay />;

    return (
        <VStack
            pt={`calc(${navbarHeight} + 1rem) !important`}
            w={"full"}
            h={"full"}
            pos={"relative"}
            overflow="auto"
            justifyContent="space-between"
            minH="100vh"
        >
            {banner && (
                <FlyerBackground
                    {...banner}
                    alt={eventFromSlug?.name || eventFromId?.name || ""}
                />
            )}
            <Outlet />
            <Show above="md">
                <Box w="full">
                    <Footer />
                </Box>
            </Show>
        </VStack>
    );
};

export default PublicEventLayout;
