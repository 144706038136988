import { EmblaPluginType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import { OptionsType } from "embla-carousel/components/Options";
import { useCallback, useEffect, useState } from "react";

export const useEmbla = (
    options?: Partial<OptionsType>,
    plugins?: EmblaPluginType[]
) => {
    const [emblaRef, emblaApi] = useEmblaCarousel(options, plugins);
    const [currentIndex, innerSetCurrentIndex] = useState(0);

    useEffect(() => {
        if (emblaApi) {
            emblaApi.on("select", () =>
                innerSetCurrentIndex(emblaApi.selectedScrollSnap())
            );
        }
    }, [emblaApi]);

    const setter = useCallback(
        (value: number) => {
            innerSetCurrentIndex(value);
            emblaApi?.scrollTo(value);
        },
        [innerSetCurrentIndex, emblaApi]
    );

    return {
        currentIndex,
        setCurrentIndex: setter,
        emblaRef,
        emblaApi,
    } as const;
};
