import { Accordion, Flex, Text, VStack } from "@chakra-ui/react";
import { BsWhatsapp } from "react-icons/bs";
import { GoBook } from "react-icons/go";
import FaqCard from "./components/FaqCard/FaqCard";
import FaqItem from "./components/FaqCard/FaqItem/FaqItem";

const faqs = [
    {
        question: "¿Cómo abono el servicio?",
        answer: `${
            import.meta.env.VITE_COMPANY_NAME
        } funciona mediante créditos, 1 crédito equivale al envío de 1 QR. Los créditos podes comprarlos desde el menú de la web haciendo clic en “Créditos”; pero no te preocupes, cuando te registres te regalaremos algunos créditos de prueba para que empieces.`,
    },
    {
        question: "¿Cómo cobro cuando vendo online?",
        answer: `Cuando vendes online cobras al instante en la billetera digital que vincules con la plataforma. ${
            import.meta.env.VITE_COMPANY_NAME
        } no te cobrara ninguna comisión extra, solo pagaras un crédito cada vez que vendas una entrada.`,
    },
    {
        question: "¿Cómo escaneo los QRs?",
        answer: `Podes escanear los QRs desde el menú en la página, esta función no tiene ningún costo extra. Una vez los escaneas, podes validarlo. De esta forma si volves a escanear el mismo QR, veras un cartel: “Este QR ya fue validado”.`,
    },
    {
        question: "¿Puedo agregar personas que trabajen conmigo?",
        answer: `Absolutamente. Para esto tenemos un sistema muy sencillo de permisos, donde cargas el correo de la persona que quieres agregar a tu equipo y seleccionas que es lo que podrá hacer para tus eventos; tenemos permisos para edición de eventos, escaneo de QRs, generación de links de referido y más.`,
    },
    {
        question: "¿Tienen una página donde aparezcan los eventos?",
        answer: `No, ${
            import.meta.env.VITE_COMPANY_NAME
        } esta pensado como una herramienta de gestión para productores de eventos, no publicamos los eventos en nuestra página web. Solo se pueden acceder a los eventos mediante su link.`,
    },
];

const FaqsSection = () => {
    return (
        <VStack w="full" alignItems="flex-start" spacing={[12, 20]}>
            <Text variant="landingTitle">PREGUNTAS FRECUENTES</Text>
            <Flex
                w="full"
                flexDir={["column-reverse", "row"]}
                gap={16}
                alignItems="stretch"
            >
                <VStack w={["full", "50%"]} spacing={10}>
                    <FaqCard
                        title={"Numero de soporte"}
                        icon={BsWhatsapp}
                        description={
                            "No te quedes con la duda. Hablá con nosotros, respondemos rápido y estamos de lunes a lunes."
                        }
                        cta={{
                            href: `https://wa.me/${
                                import.meta.env.VITE_COMPANY_WHATSAPP
                            }`,
                            label: "Envianos un mensaje a WhatsApp",
                        }}
                    />
                    <FaqCard
                        title={"Guía de uso"}
                        icon={GoBook}
                        description={
                            "Tenemos una guía completisima con todas las funciones Tikzet."
                        }
                        cta={{
                            href: import.meta.env.VITE_DOCS_URL,
                            label: "Accedé a la guía",
                        }}
                    />
                </VStack>
                <Accordion
                    w={["full", "50%"]}
                    allowToggle
                    display="flex"
                    flexDir="column"
                    justifyContent="space-evenly"
                    gap={10}
                >
                    {faqs.map((faq) => (
                        <FaqItem
                            key={faq.question}
                            question={faq.question}
                            answer={faq.answer}
                        />
                    ))}
                </Accordion>
            </Flex>
        </VStack>
    );
};

export default FaqsSection;
