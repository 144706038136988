import { alertAnatomy } from "@chakra-ui/anatomy";
import {
    createMultiStyleConfigHelpers,
    defineStyle,
    extendTheme,
} from "@chakra-ui/react";
import "../assets/fonts/fonts.css";

//#region component themes
const getAlertTheme = () => {
    const { defineMultiStyleConfig, definePartsStyle } =
        createMultiStyleConfigHelpers(alertAnatomy.keys);

    const container = defineStyle({
        '&[data-status="success"]': {
            bgColor: "rgba(0, 256, 0, .1)",
            color: "white",
        },
        '&[data-status="info"]': {
            bgColor: "rgba(50, 50, 255, .1)",
            color: "white",
        },
        '&[data-status="warning"]': {
            bgColor: "rgba(250, 255, 20, .1)",
            color: "white",
        },
        '&[data-status="error"]': {
            bgColor: "rgba(255, 0, 0, .3)",
            color: "white",
        },
        rounded: "md",
    });

    const icon = defineStyle({
        '&[data-status="success"]': {
            color: "#43A047",
        },
        '&[data-status="warning"]': {
            color: "yellow",
        },
    });

    const baseStyle = definePartsStyle({
        container,
        icon,
    });

    return defineMultiStyleConfig({
        baseStyle,
        variants: {
            toast: {
                container: defineStyle({
                    '&[data-status="success"]': {
                        bgColor: "green.600",
                        color: "white",
                    },
                    '&[data-status="info"]': {
                        bgColor: "blue.600",
                        color: "white",
                    },
                    '&[data-status="warning"]': {
                        bgColor: "yellow.600",
                        color: "white",
                    },
                    '&[data-status="error"]': {
                        bgColor: "red.600",
                        color: "white",
                    },
                    rounded: "md",
                }),
                icon: defineStyle({
                    '&[data-status="success"]': {
                        color: "white",
                    },
                    '&[data-status="warning"]': {
                        color: "white",
                    },
                }),
            },
        },
    });
};
//#endregion

export const theme = extendTheme({
    styles: {
        global: () => ({
            body: {
                bg: "black",
                overflowY: "auto",
                overflowX: "hidden",
                width: "100vw",
            },
        }),
    },
    breakpoints: {
        "2xl": "1700px",
    },
    fonts: {
        body: "Inter, sans-serif",
        heading: "Inter, sans-serif",
        landingHeading: "Barlow Condensed, sans-serif",
        landingBody: "Space Mono, sans-serif",
    },
    colors: {
        primary: "#C16DFF",
        "primary.dark": "#8749b3",
        "primary.100": "#1B0C26",
        secondary: "black",
        "secondary.500": "#1C1C1C",
        "secondary.600": "#0F0F0F",
        dark_purple: "#371451",
        darker_purple: "#1B0C26",
        dark_gray: "#0f0f0f",
        light_gray: "#161618",
        lighter_gray: "#282828",
    },
    components: {
        Button: {
            baseStyle: {
                fontFamily: "body",
            },
            variants: {
                primary: {
                    bgColor: "dark_purple",
                    colorScheme: "purple",
                    rounded: "full",
                    color: "primary",
                    borderWidth: 1,
                    borderColor: "#371451",
                    _hover: {
                        bgColor: "#5A2085",
                    },
                },
                secondary: {
                    bgColor: "transparent",
                    colorScheme: "whiteAlpha",
                    rounded: "full",
                    borderWidth: 1,
                    borderColor: "primary",
                    color: "primary",
                },
                striking: {
                    bgColor: "primary",
                    colorScheme: "purple",
                    rounded: "full",
                    color: "black",
                    _hover: {
                        bgColor: "primary.dark",
                    },
                },
            },
            sizes: {
                xs: {
                    py: ".8rem",
                },
                sm: {
                    px: 10,
                },
                md: {
                    px: 10,
                },
            },
        },
        Heading: {
            baseStyle: {
                color: "white",
            },
            variants: {
                landingHeading: defineStyle({
                    fontFamily: "landingHeading",
                    lineHeight: "100%",
                }),
            },
        },
        Text: {
            baseStyle: {
                color: "white",
            },
            variants: {
                landingTitle: defineStyle({
                    fontFamily: "landingBody",
                    color: "gray.300",
                    fontSize: { base: "md", md: "2xl" },
                    fontWeight: "bold",
                }),
            },
        },
        FormLabel: {
            baseStyle: {
                color: "white",
            },
        },
        Modal: {
            baseStyle: {
                dialog: {
                    bgColor: "light_gray",
                },
                header: {
                    borderBottomWidth: 1,
                    w: "full",
                    textAlign: "center",
                    borderColor: "gray.700",
                    color: "white",
                },
                closeButton: {
                    color: "white",
                },
            },
        },
        Input: {
            baseStyle: {
                field: {
                    rounded: "full",
                    bgColor: "dark_gray !important",
                    borderWidth: "0 !important",
                    color: "white",
                    _focus: {
                        boxShadow: "0 0 0 1px #C16DFF !important",
                    },
                },
                addon: {
                    rounded: "full",
                    bgColor: "dark_gray !important",
                    color: "gray.500",
                    borderWidth: "0 !important",
                },
            },
        },
        Divider: {
            baseStyle: {
                borderColor: "gray.600",
                width: "full",
            },
        },
        Tabs: {
            baseStyle: {
                root: {
                    bgColor: "light_gray",
                    py: 3,
                    px: 8,
                    rounded: "full",
                    borderBottomColor: "gray.700",
                },
                tablist: {
                    color: "white",
                },
                tab: {
                    _selected: {
                        color: "white",
                        borderBottomColor: "primary !important",
                    },
                    _active: {
                        background: "none",
                    },
                    flex: 1,
                },
            },
        },
        Alert: getAlertTheme(),
    },
});
