import { apiClient } from "@api/config";
import { useBoolean } from "@chakra-ui/react";
import { Axios } from "axios";
import { createContext, useContext, useEffect } from "react";
import LoadingOverlay from "../components/LoadingOverlay";
import { AuthContext } from "./AuthContext";

interface AuthenticatedAxiosContextProps {
    apiClient: Axios;
}

export const AuthenticatedAxiosContext =
    createContext<AuthenticatedAxiosContextProps>(
        {} as AuthenticatedAxiosContextProps
    );

export const AuthenticatedAxiosProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [isHeaderSet, isHeaderSetSetter] = useBoolean();
    const { auth, getIsAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        if (getIsAuthenticated()) {
            import.meta.env.DEV &&
                console.log("Api client header updated", auth?.token);
            apiClient.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${auth?.token}`;
            isHeaderSetSetter.on();
        }
    }, [getIsAuthenticated, auth?.token]);

    if (!isHeaderSet && getIsAuthenticated()) {
        return <LoadingOverlay />;
    }

    return (
        <AuthenticatedAxiosContext.Provider value={{ apiClient }}>
            {children}
        </AuthenticatedAxiosContext.Provider>
    );
};
