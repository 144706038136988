import { useTheme } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import ReactJoyride, {
    ACTIONS,
    CallBackProps,
    Props,
    STATUS,
} from "react-joyride";

interface ThemedJoyrideProps extends Props {
    tourName: string;
}

const ThemedJoyride = (props: ThemedJoyrideProps) => {
    const chakraTheme = useTheme();
    const { run, tourName, showSkipButton, steps, ...rest } = props;
    const [enabled, setEnabled] = useState(false);

    // this way we are sure that the tour won't start until the components are mounted
    useEffect(() => {
        const tourSkipped = localStorage.getItem(`tour-${props.tourName}-skip`);
        setEnabled(run && !(tourSkipped === "true"));
    }, [setEnabled, run]);

    const callback = useCallback(
        (state: CallBackProps) => {
            if (
                state.status === STATUS.SKIPPED ||
                state.status === STATUS.FINISHED ||
                state.action === ACTIONS.CLOSE
            ) {
                localStorage.setItem(`tour-${tourName}-skip`, "true");
            }
        },
        [steps, tourName]
    );

    return (
        <ReactJoyride
            {...rest}
            steps={steps.map((s) => ({
                ...s,
                disableBeacon: true,
                showProgress: true,
                disableOverlayClose: true,
            }))}
            run={enabled}
            callback={callback}
            showSkipButton={
                showSkipButton === undefined ? true : showSkipButton
            }
            styles={{
                options: {
                    primaryColor: chakraTheme.colors.primary,
                    backgroundColor: chakraTheme.colors.dark_gray,
                    arrowColor: chakraTheme.colors.dark_gray,
                    overlayColor: "rgba(0, 0, 0, 0.9)",
                    textColor: "white",
                },
            }}
            locale={{
                skip: "Saltar",
                next: "Continuar",
                back: "Atrás",
                last: "Finalizar",
            }}
        />
    );
};

export default ThemedJoyride;
