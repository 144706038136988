import LoadingOverlay from "@components/LoadingOverlay";
import { AnimatePresence } from "framer-motion";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

const PageWrap = () => {
    return (
        <Suspense fallback={<LoadingOverlay />}>
            <AnimatePresence exitBeforeEnter>
                <Outlet />
            </AnimatePresence>
        </Suspense>
    );
};

export default PageWrap;
