import { Image, Text, VStack } from "@chakra-ui/react";

interface Props {
    image: string;
    description: string;
}

const CardContent = ({ image, description }: Props) => {
    return (
        <VStack w="full" alignItems="flex-start" spacing={10}>
            <Image src={image} w="full" />
            <Text color="gray.300">{description}</Text>
        </VStack>
    );
};

export default CardContent;
