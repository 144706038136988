export const smoothScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
        const offset =
            window.innerHeight / 2 - element.getBoundingClientRect().height / 2;
        const elementPosition =
            element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
        });
    }
};
