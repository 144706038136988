import { useCallback, useEffect } from "react";
import { BsApple } from "react-icons/bs";
import SocialMediaButton from "../SocialMediaButton/SocialMediaButton";

interface Props {
    isDisabled: boolean;
}

const AppleSignIn = ({ isDisabled }: Props) => {
    useEffect(() => {
        // @ts-ignore
        window.AppleID.auth.init({
            clientId: import.meta.env.VITE_APPLE_SERVICE_ID,
            scope: "email",
            redirectURI: import.meta.env.VITE_APPLE_REDIRECT_URI,
            usePopup: false,
        });
    }, []);

    const onClick = useCallback(() => {
        // @ts-ignore
        window.AppleID.auth.signIn();
    }, []);

    return (
        <SocialMediaButton
            icon={BsApple}
            label="Continuar con Apple"
            onClick={onClick}
            isDisabled={isDisabled}
        />
    );
};

export default AppleSignIn;
