import ServicesImage from "@assets/images/home_services.webp";
import InvitationsImage from "@assets/images/home_services_invitations.webp";
import SalesImage from "@assets/images/home_services_online_sales.webp";
import { Text, VStack } from "@chakra-ui/react";
import { BsWhatsapp } from "react-icons/bs";
import ServicesItem from "./components/ServicesItem/ServicesItem";

const Services = () => {
    return (
        <VStack w="full" alignItems="flex-start" spacing={20}>
            <Text variant="landingTitle">¿POR QUÉ ELEGIR TIKZET?</Text>
            <ServicesItem
                badge={{
                    color: "orange",
                    label: "VENTA ONLINE",
                }}
                title="Vendé entradas SIN comisiones ni cargo por servicio."
                description="A diferencia de otras  ticketeras, no te vamos a cobrar comisión por venta, abonas un costo fijo. Además, cobrás al instante en tu cuenta con cada venta realizada."
                subtitle="SIN COMISIONES"
                image={SalesImage}
                cta={{
                    label: "Info sobre cobros",
                    href: `${
                        import.meta.env.VITE_DOCS_URL
                    }/pages/sales-system/collect-online.html#metodos-de-cobro`,
                }}
            />
            <ServicesItem
                badge={{
                    color: "green",
                    label: "INVITACIONES",
                }}
                title="¿No querés vender online? Usá el sistema de invitaciones."
                description="No es necesario que vendas online. Podes usar el sistema solo para gestionar ingresos. Ahorrate los costos logísticos de las entradas físicas."
                subtitle="SISTEMA DE INVITACIONES"
                image={InvitationsImage}
            />
            <ServicesItem
                badge={{
                    color: "blue",
                    label: "SISTEMA",
                }}
                title="No vas a creer lo FÁCIL que es usar la plataforma."
                description="Nos enfocamos en hacer una solución potente pero muy fácil de usar. Comenzá en minutos. Si tenés dudas y querés charlar con nosotros, podemos coordinar una llamada."
                subtitle="FÁCIL DE USAR"
                image={ServicesImage}
                cta={{
                    label: "Contacto",
                    href: `https://wa.me/${
                        import.meta.env.VITE_COMPANY_WHATSAPP
                    }`,
                    icon: BsWhatsapp,
                }}
            />
        </VStack>
    );
};

export default Services;
