import { HStack, Icon, Show, Text } from "@chakra-ui/react";
import { IoMdMenu } from "react-icons/io";
import { tourKeys } from "../../../../../pages/organizations/{id}/events/components/NavigationIntroGuidedTour/NavigationIntroGuidedTour";

const height = { base: "fit-content", md: "4.5rem" };

interface Props {
    title: string;
    toggleSidebar: () => void;
}

const TopBar = ({ title, toggleSidebar }: Props) => {
    return (
        <HStack
            w="full"
            bgColor={"darker_purple"}
            px={{ base: 5, md: 20 }}
            py={5}
            justifyContent={"flex-start"}
            h={"fit-content"}
            minH={height}
            maxH={height}
            alignItems={"flex-start"}
            spacing={5}
        >
            <Show below="lg">
                <Icon
                    color="white"
                    fontSize={"2xl"}
                    as={IoMdMenu}
                    onClick={toggleSidebar}
                    id={tourKeys.stepTwo}
                />
            </Show>
            <Text fontSize={"lg"} fontWeight={600}>
                {title}
            </Text>
        </HStack>
    );
};

export default TopBar;
