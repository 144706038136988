import { useEffect, useMemo, useState } from "react";

export const useNavbarHeight = () => {
    const [height, setHeight] = useState(0);
    const heightString = useMemo(() => `${height}px`, [height]);

    useEffect(() => {
        const navbar = document.getElementById("navbar");
        if (navbar) {
            const resizeObserver = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    if (entry.target === navbar) {
                        setHeight(entry.contentRect.height);
                    }
                }
            });

            resizeObserver.observe(navbar);

            return () => {
                resizeObserver.unobserve(navbar);
            };
        }
    }, []);

    return heightString;
};
