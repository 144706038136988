import { Button, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import ContactButton from "@components/ContactButton/ContactButton";
import { Link as RouterLink } from "react-router-dom";

const TitleSection = () => {
    return (
        <VStack
            w={{ base: "full", md: "fit-content" }}
            spacing={[6, 8]}
            alignItems={["center", "flex-start"]}
        >
            <VStack
                w="full"
                alignItems={["center", "flex-start"]}
                spacing={[6, 3]}
                textAlign={["center", "left"]}
            >
                <Heading
                    variant="landingHeading"
                    fontSize={{
                        base: "3.6rem",
                        md: "2.6rem",
                        xl: "3.5rem",
                        "2xl": "7xl",
                    }}
                >
                    GESTIÓN DE ENTRADAS, <br />
                    EN MINUTOS.
                </Heading>
                <Text
                    color="gray.400"
                    fontSize={{
                        base: "sm",
                        xl: "md",
                    }}
                >
                    Olvidate del caos en tus eventos y dale una <br />
                    impresión más profesional a tus clientes.
                </Text>
            </VStack>
            <Flex flexDir={["column", "row"]} gap={4}>
                <Button
                    as={RouterLink}
                    to={"/organizations/new?from=landing"}
                    variant={"striking"}
                >
                    Crear cuenta gratis
                </Button>
                <ContactButton />
            </Flex>
        </VStack>
    );
};

export default TitleSection;
