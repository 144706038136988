import { HStack, chakra } from "@chakra-ui/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavbarHeight } from "../../hooks/useNavbarHeight";
import Logo from "./Logo";
import NavbarAuthPrompt from "./NavbarAuthPrompt";
import NavbarItems from "./NavbarItems";

const scrollTolerance = 50;

const Navbar = () => {
    const [scrolled, setScrolled] = useState(window.scrollY > 100);
    const navbarHeight = useNavbarHeight();
    const location = useLocation();
    const inEventsPage = useMemo(
        () => location.pathname.includes("/events/"),
        [location.pathname]
    );

    // used in memo to keep navbar height in sync with blur effect
    const blurNavbarProps = useMemo(
        () => ({
            content: '""',
            w: "full",
            h: navbarHeight,
            bgColor: "rgba(0, 0, 0, 0.5)",
            pos: "absolute",
            top: 0,
            left: 0,
            backdropFilter: "blur(3px)",
            zIndex: -1,
            transition: "all .2s ease-in-out",
            opacity: scrolled ? 1 : 0,
        }),
        [navbarHeight, scrolled]
    );

    const { getIsAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > scrollTolerance);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [setScrolled]);

    return (
        <>
            <chakra.nav
                pos="fixed"
                top={0}
                left={0}
                w="full"
                zIndex={500}
                id="navbar"
                _before={blurNavbarProps}
                h="fit-content"
            >
                <HStack
                    w={"full"}
                    bgColor={"transparent"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    px={{ base: 5, md: 10 }}
                    h={"3.5rem"}
                >
                    <Logo />
                    {!inEventsPage && (
                        <HStack spacing={{ base: 3, md: 8 }}>
                            <NavbarItems />
                            {!getIsAuthenticated() && <NavbarAuthPrompt />}
                        </HStack>
                    )}
                </HStack>
            </chakra.nav>
        </>
    );
};

export default Navbar;
