import { Event } from "@api/useCases/events/queries/getEvents/useGetEventsQuery";
import { Box, Center, VStack } from "@chakra-ui/react";
import { useEmbla } from "@hooks/useEmbla";
import Autoplay from "embla-carousel-autoplay";
import { useMemo } from "react";
import EventsCarouselItem from "./components/EventsCarouselItem/EventsCarouselItem";

interface Props {
    events: Event[];
}

const EventsCarousel = ({ events }: Props) => {
    const { emblaRef } = useEmbla(
        {
            loop: true,
            align: "center",
        },
        [
            Autoplay({
                playOnInit: true,
                delay: 2000,
                stopOnInteraction: false,
                stopOnMouseEnter: true,
            }),
        ]
    );

    // Ensure events array has at least 20 items or is trimmed to 20 items
    const adjustedEvents = useMemo(
        () =>
            events.length >= 20
                ? events.slice(0, 20)
                : [
                      ...events,
                      ...Array(20)
                          .fill(null)
                          .flatMap(() => events),
                  ].slice(0, 20),
        [events]
    );

    return (
        <VStack
            overflow="hidden"
            ref={emblaRef}
            pb={{ base: "3rem", lg: "1rem", "2xl": "2rem" }}
            position="relative"
            w="100vw"
        >
            <Box display={"flex"} h={"10%"} alignItems={"start"}>
                {adjustedEvents.map((event, i) => (
                    <Center key={`${event.id}-${i}`} flex={"0 0 6%"}>
                        <EventsCarouselItem event={event} />
                    </Center>
                ))}
            </Box>
        </VStack>
    );
};

export default EventsCarousel;
