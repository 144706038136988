import { useEffect } from "react";

// Users that are in the app while a new deployment happens are getting white screens
// due to the lazy loading of the new chunks. This event listener will reload the page
// when a preload error (chunk no longer exists) happens.
const useReloadOnPreloadError = () => {
    useEffect(() => {
        window.addEventListener("vite:preloadError", () => {
            window.location.reload();
        });
    }, []);
};

export default useReloadOnPreloadError;
