import { Button, Icon, Link } from "@chakra-ui/react";
import { BsWhatsapp } from "react-icons/bs";

const ContactButton = () => {
    return (
        <Button
            variant="primary"
            as={Link}
            leftIcon={<Icon as={BsWhatsapp} />}
            href={`https://wa.me/${import.meta.env.VITE_COMPANY_WHATSAPP}`}
            target="_blank"
            textDecoration="none !important"
        >
            Contacto
        </Button>
    );
};

export default ContactButton;
