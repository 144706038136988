import { UseGetPremiumCreditsPriceQueryResponseBundle } from "@api/useCases/premiumCredits/queries/getPremiumCreditsPrice/useGetPremiumCreditsPriceQuery";
import CreditsThree from "@assets/images/1000credits.svg";
import CreditsOne from "@assets/images/20credits.svg";
import CreditsTwo from "@assets/images/300credits.svg";
import { Divider, VStack } from "@chakra-ui/react";
import { toLocaleFixed } from "@utils/numbers";
import { Fragment } from "react";
import PriceItem from "./components/PriceItem/PriceItem";

const getImageByBundleStart = (start: number) => {
    switch (start) {
        case 20:
            return CreditsOne;
        case 300:
            return CreditsTwo;
        case 1000:
            return CreditsThree;
        default:
            return CreditsOne;
    }
};

interface Props {
    bundles: UseGetPremiumCreditsPriceQueryResponseBundle[];
    unitPrice: number;
    currency: string;
}

const PriceDisplay = ({ bundles, unitPrice, currency }: Props) => {
    return (
        <VStack
            alignItems="flex-start"
            px={[6, 20]}
            py={5}
            bg="dark_gray"
            roundedTopRight={[0, "2xl"]}
            roundedBottomLeft={["2xl", 0]}
            roundedBottomRight={["2xl", "2xl"]}
            flex={1}
            spacing={6}
        >
            <PriceItem
                value={toLocaleFixed(unitPrice)}
                amount={import.meta.env.VITE_MINIMUM_CREDITS_PURCHASE}
                image={CreditsOne}
                currency={currency}
            />
            <Divider />
            {bundles
                .sort((b) => b.start)
                .map((bundle, i) => (
                    <Fragment key={i}>
                        <PriceItem
                            value={toLocaleFixed(
                                unitPrice * (1 - bundle.discount / 100)
                            )}
                            amount={bundle.start}
                            image={getImageByBundleStart(bundle.start)}
                            currency={currency}
                        />
                        {i !== bundles.length - 1 && <Divider />}
                    </Fragment>
                ))}
        </VStack>
    );
};

export default PriceDisplay;
